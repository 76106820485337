import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../../i18n/localized-link"
import { useTranslation } from "../../../i18n/translate"
import GlobeIcon from "../../../images/icons/globe.svg"
import { ProductModel } from "../../../model/productModel"
import Badge from "../../badge/badge"
import RichTextPreview from "../../rich-text/rich-text-preview"
import Tile from "../../tile/tile"
import TileContentSpacer from "../../tile/tile-content-spacer/tile-content-spacer"
import CartButton from "../actions/cart-button/cart-button"
import ComparisonButton from "../actions/comparison-button/comparison-button"
import "./product-preview.sass"

const ProductPreview = ({
  product,
  autoHeight,
  filterKey,
  small,
  productContext,
}) => {
  const { t } = useTranslation()

  if (small) {
    return (
      <Tile
        autoHeight={autoHeight}
        linkPath={product.getUrl(productContext)}
        linkTextTranslatedString={t("PRODUCTS-MORE_ABOUT_THIS_PRODUCT")}
      >
        <div className={classNames("_fp-product-preview")}>
          <div className={classNames("_fp-product-preview__heading")}>
            <>
              {product.hasAvailability() ? (
                <Badge
                  badgeSvgIconComponent={<GlobeIcon />}
                  textTranslatedString={product.getAvailabilityString(t)}
                />
              ) : (
                <Badge
                  badgeSvgIconComponent={<GlobeIcon />}
                  textTranslatedString={t(
                    "PRODUCTS-AVAILABILITY-AVAILABILITY_ON_REQUEST",
                  )}
                />
              )}
              <TileContentSpacer />
            </>
            <LocalizedLink
              tabIndex={-1}
              className={"_fp-product-preview__heading__title-link"}
              to={product.getUrl(productContext)}
              state={{ filterKey: filterKey }}
            >
              <h3
                className={
                  "_fp-heading-3 _fp-product-preview__heading__title-link__title"
                }
              >
                {product.name}
              </h3>
            </LocalizedLink>
          </div>
          <p className="_fp-text _fp-product-preview__description">
            <RichTextPreview
              textTranslatedString={t(product.description.key)}
            ></RichTextPreview>
          </p>

          <div className="_fp-product-preview__actions">
            <ComparisonButton product={product} />
            <CartButton product={product} />
          </div>
        </div>
        <TileContentSpacer />
      </Tile>
    )
  }

  return (
    <Tile
      autoHeight={autoHeight}
      linkPath={product.getUrl(productContext)}
      linkTextTranslatedString={t("PRODUCTS-MORE_ABOUT_THIS_PRODUCT")}
    >
      <div
        className={classNames(
          "_fp-product-preview",
          "_fp-product-preview--large",
        )}
      >
        <div className={classNames("_fp-product-preview__heading")}>
          <LocalizedLink
            tabIndex={-1}
            className={"_fp-product-preview__heading__title-link"}
            to={product.getUrl(productContext)}
            state={{ filterKey: filterKey }}
          >
            <h3
              className={
                "_fp-heading-3 _fp-product-preview__heading__title-link__title"
              }
            >
              {product.name}
            </h3>
          </LocalizedLink>
          <div className="_fp-grid">
            <div className="_fp-col-0 _fp-col-12st _fp-col-12p _fp-col-12sp">
              <TileContentSpacer />
            </div>
          </div>
          {product.hasAvailability() ? (
            <Badge
              badgeSvgIconComponent={<GlobeIcon />}
              textTranslatedString={product.getAvailabilityString(t)}
            />
          ) : (
            <Badge
              badgeSvgIconComponent={<GlobeIcon />}
              textTranslatedString={t(
                "PRODUCTS-AVAILABILITY-AVAILABILITY_ON_REQUEST",
              )}
            />
          )}
        </div>
        <p className="_fp-text _fp-product-preview__description">
          <RichTextPreview
            textTranslatedString={t(product.description.key)}
          ></RichTextPreview>
        </p>

        {/* Show different Button Sizes per Viewport */}
        <div className="_fp-grid">
          <div className="_fp-col-12 _fp-col-0p _fp-col-0sp">
            <div className="_fp-product-preview__actions">
              <ComparisonButton product={product} />
              <CartButton product={product} />
            </div>
          </div>
          <div className="_fp-col-0 _fp-col-12p _fp-col-12sp">
            <div className="_fp-product-preview__actions">
              <ComparisonButton product={product} />
              <CartButton product={product} />
            </div>
          </div>
        </div>
        {/*  ///  */}
      </div>
      <TileContentSpacer />
    </Tile>
  )
}

ProductPreview.propTypes = {
  product: PropTypes.instanceOf(ProductModel).isRequired,
  filterKey: PropTypes.string,
  small: PropTypes.bool,
  autoHeight: PropTypes.bool,
}

ProductPreview.defaultProps = {
  autoHeight: false,
}

export default ProductPreview
