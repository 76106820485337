import classNames from "classnames"
import { graphql } from "gatsby"
import React from "react"

import OverviewSlider from "../components/overview-slider/overview-slider"
import PageHeader from "../components/page-header/page-header"
import ProductPreview from "../components/product/product-preview/product-preview"
import FileDownloads from "../components/resources/file-downloads/file-downloads"
import SEO from "../components/seo"
import Tile from "../components/tile/tile"
import TileContentSpacer from "../components/tile/tile-content-spacer/tile-content-spacer"
import useDownloads from "../hooks/useDownloads"
import useProducts from "../hooks/useProducts"
import { useTranslation } from "../i18n/translate"
import { BrandModel } from "../model/brandModel"

const BrandPage = ({ data, location }) => {
  const { t } = useTranslation()
  const brand = new BrandModel(data.strapiBrand)

  const coverImage = data.strapiBrand.cover_image?.localFile?.childImageSharp

  const schema = (
    <script type="application/ld+json">
      {`{
            "@context": "https://schema.org/",
            "@type": "Brand",
            "@id": "${data.site.siteMetadata.siteUrl + "/#" + brand.url}",
            "name": "${t(brand.name.key)}",
            "description": "${t(brand.description?.key)}",
        }`}
    </script>
  )

  const allProducts = [
    ...brand.featured_products,
    ...useProducts(true).filter((product) => {
      return product.hasBrand(brand.url)
    }),
  ]

  const downloads = useDownloads().filter(
    (download) => download.brands.findIndex((b) => b.id === brand.id) !== -1,
  )

  // Set variable if market has download resources
  const hasResources = downloads.length > 0

  return (
    <>
      {schema}
      <SEO
        pathName={location.pathname}
        titleTranslatedString={
          t(brand.name.key) +
          (brand.getProductGroupsString(t)
            ? " - " + brand.getProductGroupsString(t)
            : "")
        }
        descriptionTranslatedString={t(brand.description?.key)}
      />
      <PageHeader
        levelThreeTitle={t(brand.name.key)}
        levelTwoTitle={t(brand.name.key)}
        levelOneTitle={t("BRANDS-META_TITLE")}
      />

      <div className="_fp-global-container _fp-grid--margin-top">
        <div className="_fp-grid _fp-grid--gap">
          <div
            className={classNames(
              hasResources ? "_fp-col-7" : "_fp-col-12",
              "_fp-col-12p",
              "_fp-col-12sp",
            )}
          >
            {brand.description?.key && (
              <Tile
                titleTranslatedString={t("BRANDS-BRAND_DETAILS")}
                linkPath={brand.getProductPageUrl()}
                linkTextTranslatedString={t("PRODUCTS-VIEW_ALL_PRODUCTS")}
              >
                <p className={"_fp-text _fp-text--columns"}>
                  {t(brand.description?.key)}
                </p>
                <TileContentSpacer size={"small"}></TileContentSpacer>
              </Tile>
            )}
          </div>

          {/* Hide resources tile if market has no resources */}
          {hasResources && (
            <div className="_fp-col-5 _fp-col-12p _fp-col-12sp">
              <Tile titleTranslatedString={t("GLOBAL-PRODUCT_BROCHURES")}>
                <FileDownloads downloads={downloads} fileStyle={"gray"} />
              </Tile>
            </div>
          )}

          <div className="_fp-col-12">
            {allProducts.length > 0 && (
              <OverviewSlider
                size={"products"}
                title={t("PRODUCTS-FEATURED_PRODUCTS")}
              >
                {allProducts.map((product) => {
                  return (
                    <ProductPreview
                      small={true}
                      product={product}
                      key={product.id}
                    />
                  )
                })}
              </OverviewSlider>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BrandPage

export const query = graphql`
  query ($brandUrl: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiBrand(url: { eq: $brandUrl }) {
      id
      url
      bu
      strapiId
      name {
        key
      }
      description {
        key
      }
      featured_products {
        id
        name
        description {
          key
        }
        availability_regions {
          id
          name {
            key
          }
        }
      }
      cover_image {
        localFile {
          childImageSharp {
            desktop: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
